/* eslint-disable */
const Config = {
    // API_BASE_URI: "http://localhost:8080/",
    // FILES_BASE_URI: "http://localhost:4040/",
    // CREATIVE_BASE_URI: "http://localhost:6060/",
    // LOCATION_BASE_URI: "http://localhost:8081/",
    FLASK_BASE_URI: "http://localhost:5000/",
    CREATIVE_BASE_URI: "https://avads.live/",
    FILES_BASE_URI: "https://int.advergic.com/",
    API_BASE_URI: "https://backend.advergic.com/",
    LOCATION_BASE_URI: "https://an.avads.live/"
};

export default Config;