/* eslint-disable */
/* eslint-disable no-unused-vars */
import React from 'react'
import axios from 'axios'
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import Config from "../config"
import Cookies from 'universal-cookie'

const cookies = new Cookies()

const TheHeaderDropdown = () => {
  const baseUri = Config.API_BASE_URI;

  function submit(){
    axios.get(baseUri + "exit")
    .then(response => {
      window.location.href = "/";
      cookies.remove("data", { path: "/" })
      cookies.remove("token", { path: "/" })
      cookies.remove("data.email", { path: "/" })
    })
    .catch(err => {
      console.log(err);
    });
  }

  return (
    <CDropdown
      inNav
      className="c-header-nav-items mx-2"
      direction="down"
    >
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <div className="c-avatar">
          <CImg
            src={'avatars/8.jpg'}
            className="c-avatar-img"
            alt="UserImage"
          />
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
         <CDropdownItem
          header
          tag="div"
          color="light"
          className="text-center"
        >
          <strong>Home</strong>
        </CDropdownItem>
        {/* <CDropdownItem>
          <CIcon name="cil-bell" className="mfe-2" />
          Updates
          <CBadge color="info" className="mfs-auto">42</CBadge>
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-envelope-open" className="mfe-2" />
          Messages
          <CBadge color="success" className="mfs-auto">42</CBadge>
        </CDropdownItem>
        <CDropdownItem
          headerh
          tag="div"
          color="light"
          className="text-center"
        >
          <strong>Settings</strong>
        </CDropdownItem>
         <CDropdownItem>
          <CIcon name="cil-settings" className="mfe-2" />
          Settings
        </CDropdownItem>*/}
        <CDropdownItem to="/dashboard">
          <CIcon name="cil-speedometer" className="mfe-2" />
          Dashboard
        </CDropdownItem>  
        <CDropdownItem divider />
        <CDropdownItem
          header
          tag="div"
          color="light"
          className="text-center"
        >
          <strong>Account</strong>
        </CDropdownItem>
       {/* <CDropdownItem to="/userprofile">
          <CIcon name="cil-user" className="mfe-2" />
          Profile
        </CDropdownItem> */}
        <CDropdownItem onClick={submit}>
          <CIcon name="cil-lock-locked" className="mfe-2" />
          LogOut
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default TheHeaderDropdown
