import React from 'react'
import { CFooter } from '@coreui/react'

const TheFooter = () => {
  return (
    <CFooter fixed={false} className="d-flex justify-content-center">
     <div className="mx-4 mx-sm-0">
        <a href="/dashboard" target="_blank" rel="noopener noreferrer">Advergic</a>
        <span className="ml-1">&copy; 2021</span>
      </div>
      <div className="mr-3 mr-sm-0 mfs-auto">
        <span className="mr-1">Powered by</span>
        <a href="https://advergic.com/" style={{color:'#321fdb'}} target="_blank" rel="noopener noreferrer">Advergic - Maximize your Ad Revenue</a>
      </div> 
    </CFooter>
  )
}

export default React.memo(TheFooter)
